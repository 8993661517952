<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'ShortUrlList',
              }"
            >{{ "短網址列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
            >{{
                isReadOnly ? "查看短網址" : isEditing ? "編輯短網址" : "新增短網址"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看短網址" : isEditing ? "編輯短網址" : "新增短網址"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                <b-form-group
                  label-cols="12"
                  label-cols-md="2"
                  label-size=""
                  label="標題"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="shortUrl.title"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-size="lg"
                  label="目標網址"
                >
                  <b-form-radio-group label-cols="2" v-model="shortUrl.url_type" stacked>
                    <b-form-radio value="oa"> LINE 官方帳號</b-form-radio>
                    <b-form-radio value="custom" >
                      自訂網址
                      <b-form-input
                        style="width: 300px"
                        class="m-2"
                        type="text"
                        v-model="shortUrl.url"
                        :readonly="isReadOnly"
                      ></b-form-input>
                    </b-form-radio>
                  </b-form-radio-group>
                  <b-form-group label-size="sm">
                    <b-form-checkbox v-model="shortUrl.line_uid_enabled" :value="true" :unchecked-value="false">
                      帶出會員 LINE ID ， LINE UID 網址參數
                      <b-form-input
                        style="width: 300px"
                        class="m-2"
                        type="text"
                        v-model="shortUrl.line_uid"
                        :readonly="isReadOnly"
                      ></b-form-input>
                    </b-form-checkbox>
                  </b-form-group>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-size="lg"
                  label="UTM"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-size="sm"
                    label="utm_source"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0 utm-input-width"
                      type="text"
                      v-model="shortUrl.utm.source"
                      :readonly="isReadOnly"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-size="sm"
                    label="utm_medium"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0 utm-input-width"
                      type="text"
                      v-model="shortUrl.utm.medium"
                      :readonly="isReadOnly"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-size="sm"
                    label="utm_campaign"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0 utm-input-width"
                      type="text"
                      v-model="shortUrl.utm.campaign"
                      :readonly="isReadOnly"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-size="sm"
                    label="utm_term"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0 utm-input-width"
                      type="text"
                      v-model="shortUrl.utm.term"
                      :readonly="isReadOnly"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-size="sm"
                    label="utm_content"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0 utm-input-width"
                      type="text"
                      v-model="shortUrl.utm.content"
                      :readonly="isReadOnly"
                    ></b-form-input>
                  </b-form-group>

                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-size="lg"
                  label="設定"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="permissionRegistered"
                    @change="handlePermissionsChange"
                  > 需註冊 </b-form-checkbox>
                </b-form-group>


                <b-form-group
                  label-cols="12"
                  label-size="lg"
                  label="觸發事件"
                >
                  <b-form-checkbox
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="shortUrl.allow_duplicated"
                  > 會員重複點擊時，允許重複觸發事件 </b-form-checkbox>

                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="貼標"
                  >
                    <div class="border rounded p-1">
                      <b-badge
                        v-for="(tag, index) in shortUrl.tags"
                        :key="tag.id"
                        variant="dark"
                        class="m-1"
                      >
                        {{ tag.name }}
                        <button class="btn btn-xs p-0" @click="handleClickRemoveTag(index)">
                          <i class="fa fa-remove text-white" />
                        </button>
                      </b-badge>
                      <b-input
                       type="text"
                        class="border-0"
                        maxlength="20"
                        placeholder="輸入標籤"
                        v-model="inputTag"
                        @keyup.enter="handleEnterInputTag"
                        @keyup="handleKeyUpInputTag"
                        @blur="handleEnterInputTag"
                      />
                    </div>

                    <template v-if="false">
                      <div class="h6 mt-3">現有標籤</div>
                      <b-button
                        v-show="false"
                        v-for="i in shortUrl.tags"
                        :key="i"
                        variant="secondary"
                        class="m-1 text-dark"
                        size="xs"
                      >
                        {{ i }}
                      </b-button>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="發放優惠卷"
                  >
                    <b-form-select
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :options="redeemCodes"
                        v-model="shortUrl.redeem_code_bundle_id"
                        :disabled="isReadOnly"
                        :readonly="isReadOnly"
                    ></b-form-select>
                  </b-form-group>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                :disabled="isSubmitting"
              >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                :disabled="isSubmitting"
              >儲存
              </b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<style scoped>
::v-deep .custom-control-label {
  justify-content: start;
  flex-wrap: wrap;
}
::v-deep .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}
::v-deep .custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .col-form-label.col-form-label-md {
  background: var(--sidebar-bg-color);
  color: #fff;
}

.utm-input-width {
  width: 300px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import shortUrlApi from "@/apis/short-url";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle"

export default {
  setup: () => ({v$: useVuelidate()}),

  validations() {
    return {
      shortUrl: {
      }
    };
  },

  watch: {
    'shortUrl.url_type': function (v) {
      if (v == 'oa') {
        this.shortUrl.url = null
      }
    },
    'shortUrl.line_uid_enabled': function (v) {
      console.log(v)
      this.shortUrl.line_uid = v ? 'line_uid' : null
    }
  },
  data() {
    return {
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      redeemCodes: [],
      tags: [],
      inputTag:'',
      shortUrl: {
        id: null,
        title: null,
        code: null,
        line_uid_enabled: true,
        line_uid: 'line_uid',
        url_type: 'oa',
        url: null,
        tags:[],
        utm: {
          source: null,
          medium: null,
          campaign: null,
          term: null,
          content: null,
        },
        redeem_code_bundle_id: null,
        allow_duplicated: false,
        config: {
          permissions: [],
        },
      },
      showLoadingUpload: false,
      permissionRegistered: true,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),

    isReadOnly() {
      return this.$route.name === "ShortUrlView";
    },
  },
  mounted() {
    if (!this.$route.params.short_url_id) {
      this.clearShortUrl();
      this.isEditing = false;
    } else {
      this.fetchShortUrl();

      this.isEditing = true;
    }
    this.fetchRedeemCodeBundle()
  },
  methods: {
    async fetchRedeemCodeBundle() {
      let page = 1, last_page = 1

      while (page <= last_page) {
        let {data}= await redeemCodeBundleApi.getRedeemCodeBundles({per_page: 100, page})
        last_page = data.meta.last_page

        this.redeemCodes = this.redeemCodes.concat(data.data.map(d => ({value: d.id, text: d.name})))
        ++page
      }
    },
    async fetchShortUrl() {
      this.showLoading = true;
      const response = await shortUrlApi.getShortUrl(
        this.organization,
        this.$route.params.short_url_id
      );
      let d = response.data.data
      this.shortUrl = {
        id: d.id,
        title: d.title,
        code: d.code,
        line_uid_enabled: !!d.line_uid,
        line_uid: d.line_uid,
        url_type: d.url_type,
        url: d.url,
        utm: {
          source: d?.utm?.source,
          medium: d?.utm?.medium,
          campaign: d?.utm?.campaign,
          term: d?.utm?.term,
          content: d?.utm?.content,
        },
        tags: d.tags,
        allow_duplicated: d.allow_duplicated,
        redeem_code_bundle_id: d.redeems.length > 0 ? d.redeems[0].id : null,
        config: d.config ?? {
          permissions: [],
        },
      };
      this.showLoading = false;

      // 控制是否顯示需註冊
      if (this.shortUrl.config.permissions.includes("registered")) {
        this.permissionRegistered = true;
      } else {
        this.permissionRegistered = false;
      }
    },

    clearShortUrl() {
      this.shortUrl = {
        id: null,
        title: null,
        code: null,
        line_uid_enabled: true,
        line_uid: 'line_uid',
        url_type: 'oa',
        url: null,
        tags:[],
        utm: {
          source: null,
          medium: null,
          campaign: null,
          term: null,
          content: null,
        },
        redeem_code_bundle_id: null,
        allow_duplicated: false,
        config: {
          permissions: [],
        },
      };
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      this.isSubmitting = true;

      try {
        if (this.isEditing) {
          let response = await shortUrlApi.updateShortUrl(
            this.organization,
            this.shortUrl
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({name: "ShortUrlList", query: this.routeQuery});
          }
        } else {
          let response = await shortUrlApi.storeShortUrl(this.shortUrl);

          if (response.status && response.status >= 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({name: "ShortUrlList", query: this.routeQuery});
          }
        }
      } catch (error) {
        console.error(error)
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
          return
        }

        if (error.response.status === 409) {
          this.$swal.fire({title: "此短網址名稱已經存在，無法新增", type: "error",});
          return
        }

        this.$swal.fire({title: this.isEditing ? "更新失敗" : "新增失敗", type: "error",});
      } finally {
        this.isSubmitting = false;
      }
    },
    handleCancel() {
      this.$router.push({name: "ShortUrlList", query: this.routeQuery});
    },
    handleEnterInputTag() {
      if (!this.inputTag) {
        return;
      }

      let isExisted = false;
      for (let i = 0; i < this.shortUrl.tags.length; i++) {
        if (this.shortUrl.tags[i].name == this.inputTag) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        this.inputTag = null;
        return;
      }

      this.shortUrl.tags.push({
        name: this.inputTag,
      });

      this.inputTag = null;
    },
    // 逗號也要當作tag 分隔
    handleKeyUpInputTag(event) {
      if (event.key == ",") {
        this.inputTag = this.inputTag.slice(0, -1);
        this.handleEnterInputTag();
      }
    },
    handlePermissionsChange(value) {
      if (value) {
        this.shortUrl.config.permissions = ["registered"];
      } else {
        this.shortUrl.config.permissions = [];
      }
    },
  },
};
</script>
